import { GameEngineProvider } from "contexts/GameEngineContext";
import { InformationOverlayProvider } from "contexts/InformationOverlayContext";
import { ScoreContextProvider } from "contexts/ScoreContext";
import React from "react";


export const AppStateProvider = (props: { children: React.ReactNode}) => (
    <GameEngineProvider>
        <ScoreContextProvider>
            <InformationOverlayProvider>
                { props.children }
            </InformationOverlayProvider>
        </ScoreContextProvider>
    </GameEngineProvider>
);
