import React  from 'react';
import goldenArrowPng from '../golden_arrow animated.png'
import banner from '../banner.svg'
import overlayStyles from '../SceneOverlay.module.scss'
import styles from './SideMenuBanner.module.scss';
import { useInformationOverlay } from 'contexts/InformationOverlayContext';

type SideMenuBannerProps = {
    idPreamble: string,
    bannerText: string,
    side: "right" | "left",
    speechBubbleTextId?: string,
    onClick: () => void
};
export const SideMenuBanner = (props: SideMenuBannerProps) => {
    const { showOverlay: isInformationOverlay, informationText } = useInformationOverlay();
    const idPreamble = props.idPreamble ? `${props.idPreamble}_` : '';
    const speechBubbleTextId = props.speechBubbleTextId ?? `${idPreamble}banner_img`
    const bubbleText = informationText[speechBubbleTextId];
    const onClick = isInformationOverlay ? () => { } : props.onClick;

    return (<div id={`${idPreamble}banner_div`} className={`${styles.statsNamePlate} ${styles[props.side]} ${styles.hover} ${styles.hover_banner}`} onClick={onClick}>
        {!!bubbleText && <div className={`${overlayStyles.speechBubble} ${overlayStyles[props.side]} ${overlayStyles.banner}`} >
            <div>{bubbleText}</div>
            <img src={goldenArrowPng} alt="golden arrow" />
        </div>}

        <img id={`${idPreamble}banner_img`} src={banner} alt='banner' className={``} />
        <div id={`${idPreamble}banner_name`} className={styles.bannerText}>{props.bannerText}</div>
    </div>);
};