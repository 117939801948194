import React, { ReactNode, useEffect } from 'react';
import './App.css';
import Play from 'pages/Play';
import { SignInProvider, useAuth, useStudent } from 'providers/SignInProvider';
import Account from 'components/account';
import Login from 'pages/Login';
import ErrorPage from 'pages/ErrorPage';
import sessionService from 'services/sessionService';
import CookieConsent from 'components/cookie_consent/CookieConsent';
import { AppStateProvider } from 'providers/AppStateProvider';

// TODO: dedupe between admin and game
const RequireLogin = (props: { children: ReactNode }): JSX.Element | null => {
  const auth = useAuth();
  const student = useStudent();

  useEffect(() => {
    sessionService.ping()
  }, [])

  if (window.location.pathname.toLowerCase() === '/error'.toLowerCase())
    return <div><ErrorPage /></div>

  // if (!auth) return <>Laddar...</>;
  if (!auth || !auth.isLoggedIn) {
    return <Login {...{ auth }} />
  }
  if (!student || !student.playerSettings) {
    return <Account />
  }
  return <>{props.children}</>;
}

function App() {
  useEffect(() => {
    // make sure that the browser not supported message is removed
    document.querySelector('.browser_not_supported')?.remove()
  }, [])
  return (<>
    <SignInProvider>
      <RequireLogin>
        <AppStateProvider>
          <Play />
        </AppStateProvider>
      </RequireLogin>
    </SignInProvider>
    <CookieConsent />
  </>);
}

export default App;
