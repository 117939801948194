import React, { createContext, useContext, useState } from "react";

interface IInformationOverlayState {
    showOverlay: boolean,
    informationText: Record<string, string | undefined>,
    setShowOverlay: (show: boolean) => void,
    handleIndex: () => void
};

const emptyContext: IInformationOverlayState = {
    showOverlay: false, informationText: {}, setShowOverlay: () => { }, handleIndex: () => { }
};
export const InformationOverlayContext = createContext<IInformationOverlayState>(emptyContext);

export const useInformationOverlay = () => {
    const context = useContext(InformationOverlayContext);
    window.sessionStorage.setItem("reload", "true");
    if (!context) {
        throw new Error("useScore must be used within a InformationOverlay.Provider");
    }
    return context;
};

export const InformationOverlayProvider = (props: { children: React.ReactNode }) => {
    const [showOverlay, setShowOverlay] = useState(false);

    const [bubbleIndex, setBubbleIndex] = useState<number>(0)

    const handleIndex = () => {
        if (bubbles.length <= bubbleIndex + 1) {
            setBubbleIndex(0)
            setShowOverlay(false)
        }
        else {
            // setBubbleIndex(n => ++n%bubbles.length)
            setBubbleIndex(n => ++n)
        }
    }
    const bubbles = [
        { type: 'control', id: 'switch_def_img', text: 'Välj spel' },
        { type: 'control', id: 'select_node_img', text: 'Välj Övningsområde' },
        { type: 'control', id: 'logout_img', text: 'Logga ut' },
        { type: 'stats', id: 'banner_img', text: 'Visa/dölj poäng' },
        { type: 'stats', id: 'total_score', text: 'Visa totalpoäng' },
        { type: 'stats', id: 'streak', text: 'Visa längsta svit' },
        { type: 'stats', id: 'timed_mode', text: 'Testa dig själv på 60 sekunder' },
        { type: 'stats', id: '100%_mode', text: 'Testa om du kan få 100% rätt' },
    ];

    const informationText = showOverlay ? { [bubbles[bubbleIndex].id]: bubbles[bubbleIndex].text } : {};

    const value: IInformationOverlayState = {
        showOverlay, setShowOverlay, informationText, handleIndex
    };

    return (
        <InformationOverlayContext.Provider value={value}>
            {props.children}
        </InformationOverlayContext.Provider>
    );
};