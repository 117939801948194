import React, { useContext } from 'react';
import styles from './Scene.module.scss';
import SpeechBubble from './SpeechBubble';
import { AreaNodeMasteries, GameMode, PointerEventHandler } from '../../engine/game'
import { GameObjectModel } from 'engine/models/gameObject';
import { SceneModel } from '../../engine/scenes';
import { GameGlobalsContext } from 'contexts/GameGlobalsContext';
import { AreaNode } from 'components/settings/AreaNodeSettings/area_nodes';
import { SaveStateHandler } from 'engine/saveStateHandler';
import { Skin } from 'components/settings/SkinChoiceSettings/SkinSettings';
import skinQuizPng from './skin-quiz-2.png'
import skinGladPng from './skin-gladiator-3.png'
import GameFrame from 'components/gameFrame';

export function GameObjectBase({ onEvent, gameObject, imageUrl }: { onEvent: PointerEventHandler, gameObject: GameObjectModel, imageUrl: string }) {
  const style = {
    top: `${gameObject.yPos}%`,
    left: `${gameObject.xPos}%`,
    opacity: gameObject.opacity,
    width: `${gameObject.width}%`,
    height: `${gameObject.height}%`,
    backgroundImage: gameObject.avatar == null ? undefined : `url('${imageUrl}')`,
  };

  const onClick = gameObject.clickable ? () => onEvent({ type: 'click', target: gameObject, position: null }) : undefined;
  return (
    <div id={gameObject.id} key={gameObject.id} className={`${styles.avatar} ${gameObject.clickable ? styles.hasInteraction : ''}`} style={style} onClick={onClick}>
      {gameObject.message && <SpeechBubble {...gameObject.message} />}
    </div>
  );
}

function GameObject({ onEvent, gameObject }: { onEvent: PointerEventHandler, gameObject: GameObjectModel }) {
  const { adventure, theme } = useContext(GameGlobalsContext);
  return <GameObjectBase gameObject={gameObject} onEvent={onEvent} imageUrl={`./assets/${adventure}-${theme}/${gameObject.avatar}.png`} />
}

function DemoGameObject({ gameObject, adventure, theme }: { gameObject: GameObjectModel, adventure: string, theme: string }) {
  const style = {
    top: `${gameObject.yPos}%`,
    left: `${gameObject.xPos}%`,
    opacity: gameObject.opacity,
    width: `${gameObject.width}%`,
    height: `${gameObject.height}%`,
    backgroundImage: gameObject.avatar == null ? undefined : `url('./assets/${adventure}-${theme}/${gameObject.avatar}.png')`
  };

  return (
    <div id={gameObject.id} key={gameObject.id} className={`${styles.avatar}`} style={style}>
      {gameObject.message && <SpeechBubble {...gameObject.message} />}
    </div>
  );
}

function sortOnZ(lh: GameObjectModel, rh: GameObjectModel) {
  if (lh.zPos === rh.zPos) return 0;
  return lh.zPos > rh.zPos ? 1 : -1;
}

const hardCodedSkins: Skin[] = [
  { name: 'frågesport', adventureId: "bare", theme: 'default', image: skinQuizPng, description: 'Utmana dig själv och dina vänner', isHorizontal: false },
  { name: 'gladiator', adventureId: "battleground", theme: 'fantasy', image: skinGladPng, description: 'Hur många motståndare kan du besegra?', isHorizontal: true },
  // { name: 'det stora äventyret', adventureId: 'eternal', theme: 'fantasy', image: skinAdvPng, description: 'Kan du lösa mysteriet?', isHorizontal: true }
];

function Scene(props: { sceneModel: SceneModel, onEvent: PointerEventHandler, saveStateHandler: SaveStateHandler, nodes: AreaNode[], masteries: AreaNodeMasteries }) {
  const { adventure, theme } = useContext(GameGlobalsContext);

  return (
    <GameFrame
        backgroundImageUrl={`./assets/${adventure}-${theme}/scene-${props.sceneModel.background}.png`}
        masteries={props.masteries}
        nodes={props.nodes}
        saveStateHandler={props.saveStateHandler}
        controlTiles={["info", "modeSwitch", "selectNode", "logout"]}
        statTiles={["totalScore", "streakScore", "stopTimer", "oneHundred"]}
>
      <>{props.sceneModel.gameObjects.sort(sortOnZ).map(gameObject => (
        <GameObject key={gameObject.id} gameObject={gameObject} onEvent={props.onEvent} />
      ))}</>
    </GameFrame>
  );
}

export function DemoScene(props: { sceneModel: SceneModel, adventure: string, theme: string }) {
  const { sceneModel, adventure, theme } = props

  return (
    <div className={styles.scene} style={{ backgroundImage: `url('./assets/${adventure}-${theme}/scene-${props.sceneModel.background}.png')` }}>
      {sceneModel.gameObjects.sort(sortOnZ).map(gameObject => (
        <DemoGameObject key={gameObject.id} gameObject={gameObject} adventure={adventure} theme={theme} />
      ))}
    </div>
  );
}

export default Scene;
