import React from 'react';
import Tooltip from 'ux_components/tooltip';
import IconButton from 'ux_components/icons';
import goldenArrowPng from '../golden_arrow animated.png'
import styles from './ScoreTile.module.scss'
import overlayStyles from '../SceneOverlay.module.scss'
import trophy from '../trophy.svg'
import fireStreak from '../fire-streak.svg'
import { useScore } from 'contexts/ScoreContext';
import { useInformationOverlay } from 'contexts/InformationOverlayContext';

type ScoreTileProps = {
  id: string,
  src: string,
  alt: string,
  score: string,
  clickable: boolean,
  tooltip?: string,
  informationSpeechBubbleId?: string,
  side: "left" | "right"
  onClick: () => void
}

const ScoreTile = (props: ScoreTileProps) => {
  const { showOverlay: isInformationOverlay, informationText } = useInformationOverlay();
  const bubbleText = informationText[props.informationSpeechBubbleId ?? props.id];
  const iconStyle = (isInformationOverlay && !bubbleText) ? overlayStyles.iconButton : '';

  const onClick = isInformationOverlay ? () => { } : props.onClick

  return (<div className={styles.tileWrapper} onClick={onClick}>
    <Tooltip {...{ id: props.id, text: !!props.tooltip && !bubbleText ? props.tooltip : '', position: props.side, style: { textAlign: 'center' }, onClick: () => { } }}>
      {bubbleText && <div className={`${overlayStyles.speechBubble} ${overlayStyles[props.side]}`} >
        <div>{bubbleText}</div>
        <img src={goldenArrowPng} alt="golden arrow" />
      </div>}
      <div className={styles.rowWrapper}>
        <div className={styles.iconWrapper}>
          <IconButton class={iconStyle} {...{ id: props.id, src: props.src, alt: props.alt, large: true, clickable: props.clickable, text: props.score, }} />
        </div>
      </div>
    </Tooltip>
  </div>);
}

export const StreakScoreTile = (props: { side: "left" | "right", onClick: () => void }) => {
  const { score } = useScore();

  return (
    <ScoreTile {...{
      id: 'streak',
      src: fireStreak,
      alt: 'fireStreak',
      side: props.side,
      score: `${score.bestScoreInARow}`,
      clickable: true,
      onClick: props.onClick
    }
    } />
  );
};


export const TotalScoreTile = (props: { side: "right" | "left" }) => {

  const { score } = useScore();

  return (<ScoreTile {...{
    id: 'total_score',
    src: trophy,
    alt: 'trophy',
    side: props.side,
    score: `${score.score}`,
    clickable: true,
    onClick: () => alert(`Du har ${score.score} poäng!`)
  }
  } />);
};


export default ScoreTile
