import React, { useEffect, useMemo, useState } from 'react';
import styles from './CreateFlashcard.module.scss';
import { CreateCards, EditFlashcard } from './CreateFlashcard';
import { useFlashCard } from 'contexts/FlashCardContext';

type flashcardProps = {
    question: string,
    answer: string,
    tags: string[],
    currentTag: string,
    isSaved: boolean,
}

type flashcardSetProps = {
    name: string,
    description: string,
    flashcards: flashcardProps[],
    tags: string[],
}

enum flashcardState {
    EditSet, EditFlashcard, Show, Finished
}
const SubmitBox = (props: { isActive: boolean, onClick: () => void, children: JSX.Element }) => {
    return <div onClick={props.onClick} className={`${styles.submitBox} ${true ? styles.isActive : styles.notActive}`}>
        {props.children}
    </div>
}

function CreateSet(props: { handleSubmit: (value: flashcardSetProps) => void }) {
    const [name, setName] = useState<string>('');
    const [description, setDescription] = useState<string>('');
    const [tags, setTags] = useState<string[]>([]);
    const [currentTag, setCurrentTag] = useState<string>('');

    const updateCard = (newValues: Partial<flashcardProps>) => {
        newValues.tags = newValues.tags ?? [];
        setCurrentTag(newValues.currentTag ?? '');
        setTags(newValues.tags);
    }

    const handleSubmit = () => {
        if (name === '') { return; }
        props.handleSubmit({ name: name, description: description, flashcards: [], tags: tags });
    }
    return <div>
        <div style={{ border: '1px solid black', padding: '50px 100px' }}>
            <div style={{ width: '175px', height: "70px" }}>
                <h4>Enter Set Name</h4>
                <input value={name} onChange={e => setName(e.target.value)} />
            </div>
            <div style={{ width: '175px', height: "70px" }}>
                <h4>Enter Set Description</h4>
                <input value={description} onChange={e => setDescription(e.target.value)} />
            </div>
            <HandleTags name={"tags"} tags={tags} currentTag={currentTag} updateCard={updateCard} />
            <SubmitBox isActive={true} onClick={handleSubmit}><h5>Save Set</h5></SubmitBox>
        </div>
    </div>
}

function HandleTags(props: { name: string, tags: string[], currentTag: string, updateCard: (value: Partial<flashcardProps>) => void }) {
    const { name, tags, currentTag, updateCard } = props;

    const handleTags = (value: string) => {
        if (value.includes(',')) {
            console.log(value);
            const tempTags = [...tags, ...value.split(',').map(t => t.trim()).filter(tag => tags.indexOf(tag) === -1)];
            const tempCurrentTag = tempTags.pop() ?? '';
            updateCard({ tags: tempTags.filter(tag => tag !== ''), currentTag: tempCurrentTag });
            console.log(tags);
        } else {
            updateCard({ tags, currentTag: value.trimStart() });
        }
    }

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            const result = [...tags, ...currentTag.split(',').map(t => t.trim()).filter(tag => tags.indexOf(tag) === -1)]
            console.log("result", result);
            updateCard({ tags: result, currentTag: '' });
        } else if (e.key === 'Backspace' && currentTag === '') {
            const tempTags = [...tags];
            updateCard({ tags: tempTags, currentTag: `${tempTags.pop() ?? ''},` });
        }
    }
    return <div>
        <span>
            {name}: {tags.map((t, i) => <span key={i}>{t}, </span>)}
            <input style={{ width: '50px' }}
                value={currentTag}
                onKeyDown={e => handleKeyDown(e)}
                onChange={e => handleTags(e.target.value)}
            />
        </span>
    </div>
}

function CreateFlashcardSet() {
    const [flashcardSet, setFlashcardSet] = useState<flashcardSetProps>();
    const [state, setState] = useState<flashcardState>(flashcardState.EditSet);
    const { setIsCreateFlashcard } = useFlashCard();


    const handleSetSubmit = (value: flashcardSetProps) => {
        setFlashcardSet(value);
        setState(flashcardState.EditFlashcard);
    }

    const handleCardsSubmit = (value: flashcardProps[]) => {
        if (!flashcardSet) { return; }
        const tempSet = { ...flashcardSet };
        tempSet.flashcards = value;
        setFlashcardSet(tempSet);
        setState(flashcardState.Show);
    }

    const updateCard = (index: number, card: flashcardProps) => {
        if (!flashcardSet) { return; }
        const tempCards = [...flashcardSet?.flashcards ?? []];
        tempCards[index] = card;
        setFlashcardSet({ ...flashcardSet, flashcards: tempCards });
    }

    const reset = () => {
        setFlashcardSet(undefined);
        setState(flashcardState.EditSet);
    }

    return <div className={styles.createFlashcard}>
        <h2 className={styles.createCardTitle}>{state === flashcardState.EditSet ? "Skapa kortlek" : "Create Flashcard"}</h2>
        <div className={styles.content} >
            work in progress
            {state === flashcardState.EditSet &&
                <CreateSet handleSubmit={handleSetSubmit}></CreateSet>}
            {state === flashcardState.EditFlashcard && <>
                <CreateCards {...{ handleCardsSubmit }}></CreateCards>
            </>}
            {state === flashcardState.Show && <div>
                <div>Set name: {flashcardSet?.name}</div>
                <div>Set Description: {flashcardSet?.description}</div>
                <div>{flashcardSet?.tags.map((tag, i) => <span key={i}>{tag}</span>)}</div>
                <div className={styles.finished + " " + styles.ScrollBox}>
                    <div className={styles.scrollList}>{flashcardSet?.flashcards.map((card, i) =>
                        <div key={i} className={styles.scrollRow} >
                            <div>
                                <div>Nr. {i + 1}</div>
                                {<button onClick={() => updateCard(i, { ...card, isSaved: !card.isSaved })}>{card.isSaved ? 'edit' : 'save'}</button>}
                            </div>
                            <EditFlashcard {...{ cardIndex: i, card, updateCard: (card) => updateCard(i, card), templateTags: [] }} ></EditFlashcard>
                        </div>
                    )}
                    </div>
                </div>
                <SubmitBox isActive={true} onClick={() => setState(flashcardState.Finished)}><h5>Finish</h5></SubmitBox>
            </div>}
            {state === flashcardState.Finished && <div>
                Finished <br />
                <div>TODO: Save to backend</div>
                <button onClick={reset}>Create new set</button>
            </div>}
        </div>
        <img style={{ gridArea: "content", height: '20px', justifySelf: "flex-end", alignSelf: "flex-start", cursor: "pointer" }} onClick={() => setIsCreateFlashcard(false)} src="/assets/close-with-background.png" alt="close" />
    </div>
}

export default CreateFlashcardSet;