import React, { useState } from 'react';
import Control, { SupportedControlTile } from '../sideMenus/Control';
import Stats, { SupportedStatTile } from '../sideMenus/Stats';
import styles from './GameFrame.module.scss';

import { AreaNodeMasteries, GameMode } from '../../engine/game'
import { GameObjectModel } from 'engine/models/gameObject';
import gameService from 'services/gameService';
import { AreaNode } from 'components/settings/AreaNodeSettings/area_nodes';
import { SaveStateHandler } from 'engine/saveStateHandler';
import SkinSettings, { Skin } from 'components/settings/SkinChoiceSettings/SkinSettings';
import AreaNodesSettings, { SettingsProps } from 'components/settings/AreaNodeSettings/AreaNodeSettings';
import skinQuizPng from '../scene/skin-quiz-2.png'
import skinGladPng from '../scene/skin-gladiator-3.png'
import { useInformationOverlay } from 'contexts/InformationOverlayContext';
import { useGameEngine } from 'contexts/GameEngineContext';

function sortOnZ(lh: GameObjectModel, rh: GameObjectModel) {
  if (lh.zPos === rh.zPos) return 0;
  return lh.zPos > rh.zPos ? 1 : -1;
}

const hardCodedSkins: Skin[] = [
  { name: 'frågesport', adventureId: "bare", theme: 'default', image: skinQuizPng, description: 'Utmana dig själv och dina vänner', isHorizontal: false },
  { name: 'gladiator', adventureId: "battleground", theme: 'fantasy', image: skinGladPng, description: 'Hur många motståndare kan du besegra?', isHorizontal: true },
  // { name: 'det stora äventyret', adventureId: 'eternal', theme: 'fantasy', image: skinAdvPng, description: 'Kan du lösa mysteriet?', isHorizontal: true }
];

const prepareSettings = (onClose: () => void, saveChanges: (skin: Skin, nodes: AreaNode[]) => void, switchGameMode: (newMode?: GameMode) => void, saveStateHandler: SaveStateHandler) => {
  return {
    skins: hardCodedSkins,
    nodes: [],
    masteries: {},
    onClose: onClose,
    saveChanges: saveChanges,
    switchGameMode: switchGameMode,
    saveStateHandler: saveStateHandler
  }
}

const handleSkinSettingsSave = async (skin: Skin, saveStateHandler: SaveStateHandler) => {
  const gameDef = await gameService.getGameDefinition(skin.adventureId, skin.theme);
  saveStateHandler.saveScene({ adventureId: gameDef.adventure, sceneId: gameDef.scenes[0].id, theme: gameDef.theme }, false).then(result =>
    window.location.reload()
  )
}

const setupSkinSettingsProps = ( 
  setOpenSkinSettingsWindow: (newValue: boolean) => void,
  handleSkinSettingsSave: (skin: Skin, saveStateHandler: SaveStateHandler) => void,
  setOpenNodeSettingsWindow: (newValue: boolean) => void,
  switchGameMode: (newMode?: GameMode) => void,
  saveStateHandler: SaveStateHandler,
) => {
  return {
    skins: hardCodedSkins,
    nodes: [],
    masteries: {},
    onClose: () => setOpenSkinSettingsWindow(false),
    saveChanges: (skin: Skin, nodes: AreaNode[]) => {
      handleSkinSettingsSave(skin, saveStateHandler);
      setOpenNodeSettingsWindow(false)
    },
    saveStateHandler: saveStateHandler,
    switchGameMode
  }
}

const handleNodeSettingsSave = async (nodes: AreaNode[]) => {
  const params = new URLSearchParams(window.location.search);
  const paramValue = params.get("node") ?? undefined;
  if (nodes[0]?.id === paramValue) {
    return
  }
  window.sessionStorage.setItem('reload', 'true');
  window.history.pushState(null, nodes.length > 0 ? nodes[0].headline : 'nodes', nodes.length > 0 ? `/?node=${nodes[0].id}` : '/')
  window.location.reload()
}

const setupNodeSettignsProps = (
  nodes: AreaNode[],
  masteries: AreaNodeMasteries,
  setOpenNodeSettingsWindow: (newValue: boolean) => void,
  handleNodeSettingsSave: (nodes: AreaNode[]) => void,
  switchGameMode: (newMode?: GameMode) => void,
  saveStateHandler: SaveStateHandler,
) => {
  return {
    skins: [],
    nodes: nodes,
    masteries,
    onClose: () => setOpenNodeSettingsWindow(false),
    saveChanges: (skin: Skin, nodes: AreaNode[], difficultyChanged?: boolean) => {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({ event: 'nodeChanged', nodeProps: { id: nodes && nodes.length > 0 ? nodes[0].id : 'Not changed', name: nodes && nodes.length > 0 ? nodes[0].headline : 'Not changed' } });
      handleNodeSettingsSave(nodes);
      setOpenNodeSettingsWindow(false);
      if (difficultyChanged) { window.location.reload() }
    },
    switchGameMode,
    saveStateHandler: saveStateHandler
  }
}

function GameFrame(props: {
    backgroundImageUrl?: string,
    saveStateHandler: SaveStateHandler,
    nodes: AreaNode[],
    masteries: AreaNodeMasteries,
    controlTiles: SupportedControlTile[],
    statTiles: SupportedStatTile[],
    children: React.ReactNode
}) {
  const { engine } = useGameEngine();

  const switchGameMode = engine?.actions.switchGameMode ?? (() => {});

  const [openSkinControlsWindow, setOpenSkinSettingsWindow] = useState<boolean>(false);
  const [openNodeControlsWindow, setOpenNodeSettingsWindow] = useState<boolean>(false);
  const [skinSettingsProps, setSkinSettignsProps] = useState<SettingsProps | undefined>(setupSkinSettingsProps(setOpenSkinSettingsWindow, handleSkinSettingsSave, setOpenNodeSettingsWindow, switchGameMode, props.saveStateHandler));
  const [nodeSettingsProps, setNodeSettignsProps] = useState<SettingsProps | undefined>(setupNodeSettignsProps(props.nodes ?? [], props.masteries, setOpenNodeSettingsWindow, handleNodeSettingsSave, switchGameMode, props.saveStateHandler));

  const { showOverlay } = useInformationOverlay();

  const leftHanded = false;

  const style = props.backgroundImageUrl ? { backgroundImage: `url('${props.backgroundImageUrl}')` } : undefined;

  return (
    <div className={styles.gameFrame} style={style}>
      {props.children}
      {!showOverlay && <>
        <Stats
          className={leftHanded ? styles.leftOverlay : styles.rightOverlay}
          saveStateHandler={props.saveStateHandler}
          tiles={props.statTiles}
        />
        <Control
            className={leftHanded ? styles.rightOverlay : styles.leftOverlay}
            tiles={props.controlTiles}
            skinSettingsCallback={() => setOpenSkinSettingsWindow(true)}
            nodeSettingsCallback={() => setOpenNodeSettingsWindow(true)}
        />
      </>}
      {openSkinControlsWindow && skinSettingsProps && <SkinSettings {...skinSettingsProps}></SkinSettings>}
      {openNodeControlsWindow && <AreaNodesSettings {...nodeSettingsProps ?? prepareSettings(() => setOpenNodeSettingsWindow(false), () => { setOpenNodeSettingsWindow(false) }, switchGameMode, props.saveStateHandler)}></AreaNodesSettings>}
    </div>
  );
}

export default GameFrame;
