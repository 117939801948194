import { GameEngine } from "engine/game";
import React, { createContext, useContext, useState } from "react";

interface IGameEngineContext {
    engine: GameEngine | null;
    setEngine: (engine: GameEngine | null) => void
};
const GameEngineContext = createContext<IGameEngineContext>({ engine: null, setEngine: () => {} });

export const GameEngineProvider = (props: { children: React.ReactNode}) => {
    const [engine, setEngine] = useState<GameEngine | null>(null);

    return <GameEngineContext.Provider value={{ engine, setEngine}}>
        {props.children}
    </GameEngineContext.Provider>
};

export const useGameEngine = () => {
    const context = useContext(GameEngineContext);
    if (!context) {
        throw new Error("useGameEngine must be used within a GameEngineProvider");
    }
    return context;
};
