import Challenge from 'components/challenge'
import { GameState, TimedGameMode } from 'engine/game'
import { PuzzleDefinition } from 'engine/models/challenge'
import { useStudent } from 'providers/SignInProvider'
import React, { useEffect, useState } from 'react'
import gameService from 'services/gameService'
import goldenArrowAnimated from './golden_arrow animated.png'
import { useScore } from 'contexts/ScoreContext'
import { useGameEngine } from 'contexts/GameEngineContext'

const basicChallange: PuzzleDefinition = {
    type: 'puzzle',
    requiredScore: 0, // non-positive numbers are treated like infinity
    playerAvatar: null,
    otherAvatar: null,
    introText: '60 sekunder',
    retreatText: 'Avsluta',
    challengeText: 'Börja spela',
    successText: '', // requiredScore is non-positive, so this will never be used
}

const TimedMode = (props: {
    state: GameState,
    timer: number,
    mode: TimedGameMode
}) => {
    const {
        state,
        timer,
    } = props

    const { score, reportScore } = useScore();

    const { engine } = useGameEngine();

    if (!engine) {
        throw new Error("Game engine must be initialized");
    }

    const [hasStarted, setHasStarted] = useState(false)
    const [hasEnded, setHasEnded] = useState(false)
    const [initialScore, setInitialScore] = useState(score.score)
    const [timedScore, setTimedScore] = useState(0)
    const [gameMode, setGameMode] = useState(props.mode)
    
    const student = useStudent()

    const completeChallenge = () => {
        setHasEnded(true)
        setHasStarted(false)
        engine.actions.retreatFromChallenge()
    }

    const handleScore = (value: number) => {
        reportScore(value)
        setTimedScore(ts => ts + value)
    }

    const playAgain = () => {
        const gm: TimedGameMode = { type: 'Timed', isTimed: true, startTime: timer ?? 0, timeLimit: 60 }
        engine.actions.switchGameMode(gm)
        setGameMode(gm)
        setInitialScore(score.score)
        engine.actions.startChallenge('id', basicChallange)
        setHasStarted(true)
        setHasEnded(false)
    }

    const back = () => {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({ event: 'timedModeFinished', timedModeProps: { score: timedScore, time: gameMode.timeLimit} });
        engine.actions.retreatFromChallenge()
        engine.actions.switchGameMode(undefined)
    }

    useEffect(() => {
        if (!hasEnded) {
            if (!hasStarted) {
                setHasEnded(false)
                engine.actions.startChallenge('id', basicChallange)
                setHasStarted(true)
            }
            else if (hasStarted && gameMode.startTime !== -1) {
                if (timer > gameMode.startTime + (gameMode.timeLimit * 1000)) {
                    setHasStarted(false)
                    setHasEnded(true)
                    console.log('score', timedScore)
                    gameService.saveTimedModeScore(timedScore, gameMode.timeLimit, student?.id?.toString() ?? '')
                }
            }
        }

    }, [engine, hasStarted, hasEnded, state.challenge, timer, gameMode, initialScore, score, student, timedScore])

    return <>
        {!hasEnded ? (state.challenge && <> <Challenge
            {...state.challenge}
            getTask={engine.getTask}
            onSuccess={completeChallenge}
            onRetreat={completeChallenge}
            saveAnswer={engine.saveStateHandler?.saveAnswer}
            timer={Math.round((timer - gameMode.startTime) / 1000)}
            score={score}
            handleScore={handleScore}
        />
        </>) : <div style={{ position: 'absolute', top: '0', bottom: '0', left: '0', right: '0', backgroundColor: '#f6d635', textAlign: 'center', }} >
            <h4>Du svarade rätt</h4>
            <h2> {timedScore}</h2>
            <h4>gånger på {gameMode.timeLimit} sekunder</h4>
            <img src={goldenArrowAnimated} style={{ maxWidth: '10%' }} alt="Golden arrow" onClick={back} />

        </div>}
    </>
}

export default TimedMode