import React, { CSSProperties, useEffect, useState } from 'react';
import { ChallengeProps, ChallengeScene } from '.';
import { WriteSentence } from '@task/TaskUtils';
import styles from './ChallengeReward.module.scss'
import treasurePng from "./treasure-1.png"
import onePng from "./1.png"
import twoPng from "./2.png"
import fivePng from "./5.png"
import extraLives from "./magical-54-color.svg"
import { useScore } from 'contexts/ScoreContext';

const getDisplayIcons = (icons: { src: string, alt: string }[]) => {
    return icons.map((icon, index) => {
        return <img key={index} className="reward_icon" src={icon.src} style={{ maxHeight: '35px' }} alt={icon.alt} />
    });
}

const ChallengeReward = (props: ChallengeProps & { handleReward: () => void }): JSX.Element => {
    const [isSetup, setIsSetup] = useState<boolean>(true)
    const [chestObjects, setChestObjects] = useState<JSX.Element[]>([])
    const [rewards, setRewards] = useState<JSX.Element>()
    const [splitReward, setSplitReward] = useState<JSX.Element[]>([])
    const [rerun, setRerun] = useState<boolean>(false)
    const [showContinue, setShowContinue] = useState<boolean>(false)
    const [readyForChoice, setReadyForChoice] = useState<boolean>(false)
    const { reportScore } = useScore()

    useEffect(() => {

        const randomizeOrder = (array: { index: number, value: number, type: string, icons: { src: string, alt: string }[] }[]) => {
            let tempArray = array.sort(() => Math.random() - 0.5);
            tempArray.forEach((element, index) => {
                element.index = index + 1;
            });
            tempArray = array.sort((a, b) => a.type === b.type ? a.value - b.value : a.type === 'points' ? -1 : 1);
            return tempArray;
        }

        const originalOne = () => {
            const possibleRewards = [
                { odds: 5, value: 5, type: 'points', icons: [{ src: fivePng, alt: "five" }] },
                { odds: 4, value: 15, type: 'points', icons: [{ src: onePng, alt: "one" }, { src: fivePng, alt: "five" }] },
                { odds: 4, value: 25, type: 'points', icons: [{ src: twoPng, alt: "two" }, { src: fivePng, alt: "five" }] },
                { odds: 3, value: 1, type: 'lives', icons: [{ src: extraLives, alt: "extra life" }] }
            ]
            const chosenRewards: { odds: number, value: number, type: string, icons: { src: string, alt: string }[] }[] = []
            const nrOfRewards = 3
            while (chosenRewards.length < nrOfRewards) {
                let availableRewards = possibleRewards;
                // don't allow more than 2 of the same reward
                availableRewards = possibleRewards.filter(pr => chosenRewards.filter(cr => cr == pr).length < 2)

                const random = Math.floor(Math.random() * availableRewards.map(pr => pr.odds).reduce((att, cur) => att += cur)) + 1
                let sum = 0
                for (let i = 0; i < availableRewards.length; i++) {
                    sum += availableRewards[i].odds
                    if (random <= sum) {
                        chosenRewards.push(availableRewards[i])
                        break
                    }
                }
            }
            return chosenRewards.map((reward, index) =>
                ({ index: index + 1, value: reward.value, type: reward.type, icons: reward.icons })
            );
        }

        const variantOne = () => {
            const possibleRewards = [
                { odds: 10, value: 5, type: 'points', icons: [{ src: fivePng, alt: "five" }] },
                { odds: 5, value: 15, type: 'points', icons: [{ src: onePng, alt: "one" }, { src: fivePng, alt: "five" }] },
                { odds: 2, value: 25, type: 'points', icons: [{ src: twoPng, alt: "two" }, { src: fivePng, alt: "five" }] },
                { odds: 1, value: 1, type: 'lives', icons: [{ src: onePng, alt: "extra life" }] }
            ]
            const chosenRewards: { odds: number, value: number, type: string, icons: { src: string, alt: string }[] }[] = []
            while (chosenRewards.length < 3) {
                const remainingRewards = possibleRewards.filter(pr => !chosenRewards.includes(pr))
                const random = Math.floor(Math.random() * remainingRewards.map(pr => pr.odds).reduce((att, cur) => att += cur)) + 1
                let sum = 0
                for (let i = 0; i < remainingRewards.length; i++) {
                    sum += remainingRewards[i].odds
                    if (random <= sum) {
                        chosenRewards.push(remainingRewards[i])
                        break
                    }
                }
            }
            return chosenRewards.map((reward, index) =>
                ({ index: index + 1, value: reward.value, type: reward.type, icons: reward.icons }));
        }

        const getOptions = () => {
            const chosenRewards = originalOne();
            return chosenRewards.map((reward, index) =>
                ({ index: index + 1, value: reward.value, type: reward.type, icons: reward.icons }));
        }

        const randomizedChoices = randomizeOrder(getOptions());

        const dropReward = (rewards: { index: number, value: number, icons: { src: string, alt: string }[] }[], index: number = 0) => {
            if (rewards.length <= index) setRewards(<></>);
            else {
                setRewards(<h1 key={index} className={styles.rewardNumber}>
                    {getDisplayIcons(rewards[index].icons)}
                </h1>)
                setTimeout(() => {
                    setRewards(<h1 key={index} className={`${styles.rewardNumber} ${styles.rewardNumberDrop}`}>
                        {getDisplayIcons(rewards[index].icons)}
                    </h1>)
                    setTimeout(() => {
                        if (rewards.length > index) {
                            dropReward(rewards, index + 1);
                        }
                        else {
                            setRewards(<></>)
                        }
                    }, 2000)
                }, 1)
            }
        }

        const splitRewards = () => {
            setChestObjects([
                imageDiv({ index: 1, value: 1, type: 'none', icons: [] }, 0, { className: styles.moveLeft, style: { gridRow: 1, gridColumn: 2 } }),
                imageDiv({ index: 1, value: 1, type: 'none', icons: [] }, 0, { style: { gridRow: 1, gridColumn: 2 } }),
                imageDiv({ index: 1, value: 1, type: 'none', icons: [] }, 0, { className: styles.moveRight, style: { gridRow: 1, gridColumn: 2 } })
            ])
        }
        // setIsSetup(false)
        if (!isSetup) {
            const rewards = randomizedChoices.map((reward, index) => {
                return imageDiv(reward, index);
            })
            setChestObjects(rewards);
        } else {
            setRewards(<></>)
            setSplitReward([])
            setChestObjects([imageDiv({ index: 0, value: 0, type: 'none', icons: [] }, 0, { style: { gridColumn: 2 } })])
            dropReward(randomizedChoices, 0);
            setTimeout(() => {
                splitRewards();
                setTimeout(() => {
                    const rewards = randomizedChoices.map((reward) => {
                        setReadyForChoice(true)
                        return imageDiv(reward, reward.index, { style: { gridRow: 1, gridColumn: reward.index }, withClick: true, choices: randomizedChoices });
                    })
                    setChestObjects(rewards);
                }, 5000)
            }, 6004);
            setTimeout(() => {
                // setIsSetup(false)
                setRerun(false)
            }, 12004)
        }
    }, [isSetup])


    const imageDiv = (reward: { index: number, value: number, type: string, icons: { src: string, alt: string }[] }, index: number, options?: { choice?: number, className?: string, style?: CSSProperties, withClick?: boolean, choices?: { index: number, value: number, type: string, icons: { src: string, alt: string }[] }[], testId?: string }) => {
        if (options && !options.choice) return <div className={`${styles.rewardDiv}`} style={options?.style}><img key={index} className={`${styles.reward} ${options?.className ?? ''} ${options?.withClick ? styles.glow : ''}`} src={treasurePng} alt="treasure" onClick={options?.withClick ? () => handleReward(reward, options?.choices ?? []) : () => { }} /></div>
        return <div className={`${styles.rewardDiv}`} style={options?.style} data-testid={options?.testId ? options.testId : ''}>
            {options?.choice === reward.index ? <img key={index} className={`${styles.reward} ${options?.className ?? ''} ${styles.chosen} ${options?.withClick ? styles.glow : ''}`} src={treasurePng} alt="treasure" onClick={options?.withClick ? () => handleReward(reward, options?.choices ?? []) : () => { }} />
                : <img key={index} className={`${styles.reward} ${styles.notChosen} ${options?.className ?? ''} ${options?.withClick ? styles.glow : ''}`} src={treasurePng} alt="treasure" onClick={options?.withClick ? () => handleReward(reward, options?.choices ?? []) : () => { }} />}
        </div>
    }

    const saveBonusPoints = (options?: { value: number, type: string }) => {
        props.onSuccess(options);
        if (options?.type === 'points') {
            reportScore(options.value);
        }
    }

    const handleReward = (choice: { index: number, value: number, type: string, icons: { src: string, alt: string }[] }, choices: { index: number, value: number, type: string, icons: { src: string, alt: string }[] }[]) => {
        const rewards = choices.map((reward) => {
            return imageDiv(reward, reward.index, { choice: choice.index, style: { gridRow: 1, gridColumn: reward.index } });
        })
        rewards.push(<div className={`${styles.glow} reward_icon_div`} data-testid="reward_icon_div" style={{ gridRow: 1, gridColumn: choice.index, display: 'flex', justifyContent: 'center', cursor: 'pointer', padding: '5px', width: 'fit-content', justifySelf: 'center' }} onClick={() => saveBonusPoints(choice)} >
            <h1 key={choice.index} className={`${styles.rewardRaise}`} style={{ alignSelf: 'center' }} >{getDisplayIcons(choice.icons)}</h1>
        </div>)
        setChestObjects(rewards);
        setTimeout(() => {
            setShowContinue(true);
        }, 3500)
        // props.handleReward()
    }

    return (
        <ChallengeScene {...{ ...props, otherAvatar: '' }}>
            {/* <button onClick={() => [saveBonusPoints(1)]}> test skip</button> */}
            {showContinue ? <b><WriteSentence sentenceFragment={"Ta din belöning"} /></b> :
                readyForChoice ? <b><WriteSentence sentenceFragment={"Välj en belöning"} /></b>
                    : <></>
            }
            <div className={styles.rewardModule}>
                <div className={styles.rewardOptions}>
                    {rewards}
                    {chestObjects}
                </div>
            </div>
            {/* {showArrow ? <img src={goldenArrowAnimated} className={styles.continueButton} alt="Golden arrow" onClick={succed} /> : <></>} */}
            {/* <div><button onClick={clear} >clear</button></div> */}
            {/* <div><button onClick={() => { setIsSetup(true); setRerun(true) }} >reset</button></div> */}
        </ChallengeScene>
    );
}

export default ChallengeReward;