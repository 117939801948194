
import React, { useEffect, useMemo, useState } from 'react';
import styles from './CreateFlashcard.module.scss';
import circlePng from './circle.png';
import { FlashcardBodyElement, FlashCardElement } from './CreateFlashCardElement';

const SubmitBox = (props: { isActive: boolean, onClick: () => void, children: JSX.Element }) => {
    return <div onClick={props.onClick} className={`${styles.submitBox} ${true ? styles.isActive : styles.notActive}`}>
        {props.children}
    </div>
}

function HandleTags(props: { name: string, tags: string[], currentTag: string, updateCard: (value: Partial<flashcardProps>) => void }) {
    const { name, tags, currentTag, updateCard } = props;

    const handleTags = (value: string) => {
        if (value.includes(',')) {
            console.log(value);
            const tempTags = [...tags, ...value.split(',').map(t => t.trim()).filter(tag => tags.indexOf(tag) === -1)];
            console.log(tempTags);
            const tempCurrentTag = tempTags.pop() ?? '';
            updateCard({ tags: tempTags.filter(tag => tag !== ''), currentTag: tempCurrentTag });
            console.log(tags);
        } else {
            updateCard({ currentTag: value.trimStart() });
        }
    }

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            const result = [...tags, ...currentTag.split(',').map(t => t.trim()).filter(tag => tags.indexOf(tag) === -1)]
            updateCard({ tags: result, currentTag: '' });
        } else if (e.key === 'Backspace' && currentTag === '') {
            const tempTags = [...tags];
            updateCard({ tags: tempTags, currentTag: `${tempTags.pop() ?? ''},` });
        }
    }
    return <div>
        <span>{name}: {tags.map((t, i) => <span key={i}>{t}, </span>)}
            <input style={{ width: '50px' }}
                value={currentTag}
                onKeyDown={e => handleKeyDown(e)}
                onChange={e => handleTags(e.target.value)}
            />
        </span>
    </div>
}

type flashcardProps = {
    question: string,
    answer: string,
    tags: string[],
    currentTag: string,
    isSaved: boolean,
}

const checkIfCardIsValid = (card: flashcardProps) => {
    const quest = card.question.trim();
    const isQuest = quest.length > 0;
    const ans = card.answer.trim();
    const isAns = ans.length > 0;
    return isQuest && isAns;
}

export function EditFlashcard(props: {
    cardIndex: number,
    card: flashcardProps,
    updateCard: (card: flashcardProps) => void,
    templateTags: string[],
    isBack?: boolean
}) {
    const [currentCard, setCurrentCard] = useState<flashcardProps>(props.card);
    const [showFront, setShowFront] = useState<boolean>(props.isBack === true ? false : true);
    const [rotate, setRotate] = useState<boolean>(false);
    useEffect(() => {
        setCurrentCard(props.card);
    }, [props.card])

    const flipCard = () => {
        setShowFront(sf => !sf);
        setRotate(false);
    }

    // TODO. handle rotate better
    const startRotate = () => {
        setRotate(true);
        setTimeout(() => {
            setRotate(false);
        }, 1150);
    }

    const updateCard = (newValues: Partial<flashcardProps>) => {
        setCurrentCard({ ...currentCard, ...newValues });
        props.updateCard({ ...currentCard, ...newValues });
    }

    return <div>
        <FlashCardElement isEdit={!currentCard.isSaved} rotateCard={rotate} flipCard={flipCard}>
            <div className={styles.editCardTop}>
                <div>{showFront ? "Frontside" : "Backside"}</div>
                {<img style={{ height: '25px', marginLeft: "auto" }} src={circlePng} alt="flip card" onClick={startRotate} />}
            </div>
            {showFront ?
                <FlashcardBodyElement isEditable={!currentCard.isSaved} text={currentCard.question} placeholder={"Enter Question"} setText={(val) => updateCard({ question: val })} onKeyDown={() => { }} />
                : <FlashcardBodyElement isEditable={!currentCard.isSaved} text={currentCard.answer} placeholder={"Enter Answer"} setText={(val) => updateCard({ answer: val })} onKeyDown={() => { }} />
            }
        </FlashCardElement>
    </div>
}

type createFlashcardSideProps = {
    className?: string,
    currentIndex: number,
    value: string,
    updateValue: (value: string) => void,
    saveCard: () => void,
    isBack?: boolean
}

function CreateFlashcardSide(props: createFlashcardSideProps) {
    const [value, setValue] = useState<string>(props.value);
    const onKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
        // console.log("e.key", e.key);
        if (e.key === 'Enter') {
            props.saveCard();
        }
    }

    useEffect(() => {
        setValue(props.value);
    }, [props.value])

    return <div>
        <FlashCardElement className={props.className} isEdit={false}>
            {/* <div className={styles.hideInTranform}>Cardnr. {props.currentIndex + 1}</div> */}
            <div className={styles.hideInTranform} style={{ flexGrow: "1" }}>{!props.isBack ? "Frontside" : "Backside"}</div>
            <FlashcardBodyElement isEditable={true} text={value} placeholder={props.isBack ? "Enter Answer" : "Enter Question"} setText={props.updateValue} onKeyDown={onKeyDown} />
        </FlashCardElement>
    </div>
}

function CreateFlashcard(props: {
    currentIndex: number,
    card: flashcardProps,
    updateCard: (card: flashcardProps) => void,
    handleSubmit: (value: flashcardProps) => void
    templateTags: string[],
    lastQuestion?: string,
    isBack?: boolean
}) {
    const [currentCard, setCurrentCard] = useState<flashcardProps>({ question: "", answer: "", tags: [], currentTag: "", isSaved: false });
    const [frontCardProps, setFrontCardProps] = useState<Omit<createFlashcardSideProps, "value">>();
    const [backCardProps, setBackCardProps] = useState<Omit<createFlashcardSideProps, "value">>();

    const updateCard = (field: string, value: string) => {
        setCurrentCard(cc => ({ ...cc, [field]: value }));
    }
    const saveCard = () => {
        handleNextCard();
    }
    useEffect(() => {
        setFrontCardProps({ ...props, isBack: false, updateValue: (value) => updateCard('question', value), saveCard: saveCard });
        setBackCardProps({ ...props, isBack: true, updateValue: (value) => updateCard('answer', value), saveCard: saveCard });
    }, [props.card])

    useEffect(() => {
        setCurrentCard(props.card);
    }, [props.card])

    const [setMerge, setSetMerge] = useState<boolean>(false);
    const handleNextCard = () => {
        if (!checkIfCardIsValid(currentCard)) { return; }
        setSetMerge(true);
        setTimeout(() => {
            props.handleSubmit(currentCard);
            setCurrentCard({ question: "", answer: "", tags: [], currentTag: "", isSaved: false });
            setSetMerge(false);
        }, 800);
    }

    const [flipIn, setFlipIn] = useState<boolean>(false);
    useEffect(() => {
        setFlipIn(true);
        setTimeout(() => {
            setFlipIn(false);
        }, 1000);
    }, [props.currentIndex])

    return <>
        <div className={`${styles.currentCardRow} ${flipIn ? styles.flipInX : ''}`}>
            {frontCardProps && <CreateFlashcardSide className={`${styles.flipInFrontX} ${setMerge ? styles.moveRight : undefined}`} {...frontCardProps} value={currentCard.question} saveCard={saveCard} />}
            {backCardProps && <CreateFlashcardSide className={`${styles.flipInBackX} ${setMerge ? styles.moveLeft : undefined}`} {...backCardProps} value={currentCard.answer} saveCard={saveCard} />}
        </div>
        <SubmitBox isActive={checkIfCardIsValid(currentCard)} onClick={handleNextCard}>
            {<h5>Next Card</h5>}
        </SubmitBox>
    </>
}

export function CreateCards(props: { handleCardsSubmit: (value: flashcardProps[]) => void }) {
    const [cards, setCards] = useState<flashcardProps[]>([{ question: "", answer: "", tags: [], currentTag: "", isSaved: false }]);
    const [currentIndex, setCurrentIndex] = useState<number>(0);
    const [templateTags, setTemplateTags] = useState<string[]>([]);
    const [currentTemplateTag, setCurrentTemplateTag] = useState<string>('');
    const bottomRef = React.useRef<HTMLDivElement>(null);


    const updateCard = (index: number, card: flashcardProps) => {
        const tempCards = [...cards];
        tempCards[index] = card;
        setCards(tempCards);
    }

    const updateCurrentCard = (card: flashcardProps) => {
        console.log(card);
        const tempCards = [...cards];
        tempCards[currentIndex] = card;
        setCards(tempCards);
    }

    const handleSaveCard = (currentCard: flashcardProps) => {
        if (!checkIfCardIsValid(currentCard)) { return; }
        const tempCards = [...cards];
        const tempCurrentCard = currentCard;
        tempCurrentCard.isSaved = true;
        if (tempCurrentCard.currentTag.trim() !== '') {
            tempCurrentCard.tags.push(tempCurrentCard.currentTag.trim());
        }
        tempCurrentCard.currentTag = '';
        tempCards[currentIndex] = tempCurrentCard;
        const unsaved = tempCards.filter(card => !card.isSaved)
        if (unsaved.length === 0) {
            tempCards.push({ question: "", answer: "", tags: [], currentTag: "", isSaved: false });
        }
        setCards(tempCards);
        setCurrentIndex(tempCards.findIndex(card => !card.isSaved));
    }

    useEffect(() => {
        bottomRef.current?.scrollIntoView({ behavior: 'smooth' });
    }, [cards.length]);

    const handleSave = () => {
        const lastCard = cards[cards.length - 1];
        if (!checkIfCardIsValid(lastCard)) {
            props.handleCardsSubmit(cards.filter((c, i) => i !== cards.length - 1))
        } else {
            props.handleCardsSubmit(cards);
        }
    }

    return <>
        <div className={styles.ScrollBox}>
            <div className={styles.scrollList}>
                {cards.filter((c, i) => i !== cards.length - 1).map((card, i) =>
                    <div className={styles.scrollRow} ref={i === cards.length - 2 ? bottomRef : undefined}>
                        <div>
                            <div>Nr. {i+1}</div>
                            {<button onClick={() => updateCard(i, { ...card, isSaved: !card.isSaved })}>{card.isSaved ? 'edit' : 'save'}</button>}
                        </div>
                        <EditFlashcard {...{ cardIndex: i, card, updateCard: (card) => updateCard(i, card), templateTags }} ></EditFlashcard>
                    </div>
                )}
            </div>
        </div>
        <CreateFlashcard {...{ currentIndex: currentIndex, card: cards[currentIndex], updateCard: updateCurrentCard, handleSubmit: handleSaveCard, templateTags, lastQuestion: cards.length > 1 ? cards[cards.length - 2].question : undefined }} ></CreateFlashcard>
        {cards.length > 1 && <SubmitBox isActive={cards.length > 1} onClick={handleSave}><h5>Save Set</h5></SubmitBox>}
    </>;

}