import React, {  useEffect, useState } from "react";
import { useStudent } from "providers/SignInProvider";
import gameService from "services/gameService";
import ScoreTile from "./ScoreTiles";
import oneHundred from '../oneHundred.svg'
import { useGameEngine } from "contexts/GameEngineContext";

export const OneHundredTile = (props: { side: "right" | "left" }) => {
    const student = useStudent();
    const [score, setScore] = useState<number>();
    const { engine } = useGameEngine();
  
    useEffect(() => {
      let running = true
      if (score === undefined && student && student.id) {
        setScore(0)
        if (running) {
          gameService.getOneHundredModeScore(student.id.toString()).then(result => {
            const mode = result['mode'] ?? { 'score': 0 }
            const score = mode['score'] ?? 0
            const questionLimit = mode['questionLimit'] ?? 0
            console.log('score', score, 'questionLimit', questionLimit)
            setScore(questionLimit !== 0 ? score / questionLimit * 100 : 0)
          }).catch(e => {
            console.error('error', e)
            setScore(0)
          })
        }
      }
      return () => { running = false }
    }, [score, student]);
  
    return <ScoreTile {...{
        id: '100%_mode',
        src: oneHundred,
        alt: 'oneHundred',
        side: props.side,
        score: !!score ? `${score}%` : '0',
        tooltip: 'Testa dig själv',
        clickable: true,
        onClick: () => engine?.actions.switchGameMode({ type: 'OneHundred', isTimed: false, })
      }} />
  }
  
  export default OneHundredTile
  