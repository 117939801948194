import React from "react";
import GameFrame from "components/gameFrame";
import { SaveStateHandler } from "engine/saveStateHandler";

export const FlashCardScene = (props: { children: React.ReactNode }) => {

    const saveStateHandler: SaveStateHandler = {
        saveScene: () => Promise.resolve(),
        loadScene: () => Promise.resolve(null),
        saveChallenge: () => {},
        loadChallenge: () => null,
        saveAnswer: () => {},
        uploadAnswers: () => {},
        taskActivities: []
    }

    return (
        <GameFrame
            backgroundImageUrl={`./assets/bare-default/scene-menu.png`}
            masteries={{}}
            nodes={[]}
            controlTiles={["info", "modeSwitch", "selectNode", "addFlashCard", "logout"]}
            statTiles={["totalScore", "streakScore", "stopTimer", "oneHundred"]}
            saveStateHandler={saveStateHandler}
        >
            {props.children}
        </GameFrame>
    )
}
