import React, { useContext, useEffect, useState } from 'react';
import { GameGlobalsContext } from 'contexts/GameGlobalsContext';
import { SaveStateHandler } from 'engine/saveStateHandler';
import { useStudent } from 'providers/SignInProvider';
import gameService from 'services/gameService';
import styles from './sideMenus.module.scss'
import { StatModal } from 'components/statsModal/statModal';
import { SideMenuBanner } from './menuTiles/SideMenuBanner';
import StopTimerTile from './menuTiles/StopTimerTile';
import OneHundredTile from './menuTiles/OneHundredTile';
import { useScore } from 'contexts/ScoreContext';
import { useInformationOverlay } from 'contexts/InformationOverlayContext';
import { StreakScoreTile, TotalScoreTile } from './menuTiles/ScoreTiles';

export type TimerProps = {
  seconds: number,
  setSeconds: (value: number) => void,
  isActive: boolean,
  setIsActive: (value: boolean) => void
}

const checkReload = () => {
  const test = window.sessionStorage.getItem('reload');
  window.sessionStorage.removeItem('reload');
  return test === "true";
}

export type SupportedStatTile = "totalScore" | "streakScore" | "stopTimer" | "oneHundred";

function Stats(props: {
  className: string,
  saveStateHandler?: SaveStateHandler,
  tiles: SupportedStatTile[] // TODO: By better dealing with "showStats", tiles can be children instead - manage "side" will be remaining to solve
//  children: React.ReactNode
}) {
  const student = useStudent();
  const { score } = useScore();

  const [show, setShow] = useState(true)
  const [showStats, setShowStats] = useState(true);

  const gameGlobals = useContext(GameGlobalsContext);

  const { showOverlay } = useInformationOverlay();

  useEffect(() => {
    const isReloaded = checkReload()
    if (isReloaded === true) {
      setShowStats(false)
    }
  }, [])

  const toTimed = async () => {
    const gameDef = await gameService.getGameDefinition('timed', 'default');
    props.saveStateHandler?.saveScene({ adventureId: gameDef.adventure, sceneId: gameDef.scenes[0].id, theme: gameDef.theme }, true).then(result =>
      window.location.reload()
    )
  }

  return (
    <div className={props.className} style={{ textAlign: 'center' }}>
      <SideMenuBanner
        idPreamble=''
        bannerText={student?.playerSettings?.nickname ?? ""}
        side='right'
        onClick={() => setShow(s => !s)}
      />

      {<div className={`${show ? (styles.scores + ' ' + styles.show) : styles.scores} ${styles.right}`}  style={showOverlay ? { zIndex: 0 } : {}}>

        {props.tiles.map(tile => {
          switch (tile) {
            case "totalScore":
              return <TotalScoreTile side="right" />;
            case "streakScore":
              return <StreakScoreTile side="right" onClick={() => setShowStats(s => !s)} />;
            case "stopTimer":
              return <StopTimerTile side="right" />;
            case "oneHundred":
              return <OneHundredTile side="right" />;
          }
        })}        
        
        {showStats && <StatModal stat={[`${score.loginStreak} ${score.loginStreak === 1 ? 'dag' : 'dagar'} i rad`, `${score.bestScoreInARow} ${score.bestScoreInARow === 1 ? 'korrekt' : 'korrekta'} svar i rad`]} avatar="knight_running" title="Dina sviter" onClose={() => setShowStats(false)} />}

        {/* <div>Speltid: <br /> {displayTime()} min</div> */}
      </div>}
    </div>
  );
}

export default Stats;
