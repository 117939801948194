import React from "react";
import styles from "./FlashCard.module.scss";

const RecommendedButton = (props: { label: string, onClick: () => void }) => {
    return (
        <div className={styles.recommendedButton} onClick={props.onClick}>
            <div>
                {props.label}
            </div>
        </div>
    );
};

export const FlashCardStartPage = (props: {
    launchers: { default: () => void, recommended: { label: string, onClick: () => void }[] }
}) => (<>
    <div
        id="startButton"
        className={`${styles.gameObject} ${styles.startButton}`}
        onClick={props.launchers.default}
    ></div>
    <div
        id="goldenArrow"
        className={`${styles.gameObject} ${styles.goldenArrow}`}
        onClick={props.launchers.default}
    ></div>
    <div className={styles.recommended}>
        {props.launchers.recommended.map(r =>
            <RecommendedButton {...r}></RecommendedButton>
        )}
    </div>
</>);
