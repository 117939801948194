import { createContext, useContext } from "react";

export interface IFlashCardState {
    isCreateFlashcard: boolean;
    setIsCreateFlashcard: (value: boolean) => void;
};
const emptyFlashCardState: IFlashCardState = {
    isCreateFlashcard: false,
    setIsCreateFlashcard: () => {}
};
export const FlashCardContext = createContext<IFlashCardState>(emptyFlashCardState);

export const useFlashCard = () => {
    const context = useContext(FlashCardContext);
    if (!context) {
        throw new Error("useFlashCard must be used within a FlashCardProvider");
    }
    return context;
};
