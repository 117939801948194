import FlashCard, { FlashProps } from 'components/flashCard';
import { GameState } from 'engine/game';
import React, { useState } from 'react';
import CreateFlashcardSet from 'components/flashCard/createFlashcard/CreateFlashcardSet';
import { Score, ScoreContext, useScore } from 'contexts/ScoreContext';
import { useGameEngine } from 'contexts/GameEngineContext';
import { FlashCardContext } from 'contexts/FlashCardContext';
import { FlashCardStartPage } from 'components/flashCard/FlashCardStartPage';
import { FlashCardScene } from 'components/flashCard/FlashCardScene';

const FlashCardMode = () => {

    const { engine } = useGameEngine();

    const [score, setScore] = useState<Score>({ score: 0, loginStreak: 0, scoreInARow: 0, bestScoreInARow: 0 }); // TODO: Create proper FlasCard score management
    const reportScore = (value: number) => {
        const tempScore = {...score};
        if (value > 0) {
            tempScore.score = +score.score + value;
            tempScore.scoreInARow = score.scoreInARow + 1;
            if (tempScore.scoreInARow > tempScore.bestScoreInARow) {
                tempScore.bestScoreInARow = tempScore.scoreInARow;
            }
        } else {
            tempScore.scoreInARow = 0;
        }
        setScore(tempScore);
    }

    if (!engine) {
        throw new Error("Game engine must be initialized");
    }

    const [isFlashCard, setIsFlashCard] = useState<boolean>(false);
    const [isCreateFlashcard, setIsCreateFlashcard] = useState<boolean>(false);
    const [nrOfQuestions, setNrOfQuestions] = useState<number>(5);
    const flashProps: FlashProps = {
        nrOfQuestions: nrOfQuestions,
        getTask: () => { console.log('getTask'); return engine.getTask() },
        onRetreat: () => { console.log('onRetreat'); setIsFlashCard(false) },
        onSuccess: (options?: { value: number, type: string }) => { console.log('done'); setIsFlashCard(false) }
    }

    const launchQuestions = (props: {nrOfQuestions: number}) => {
        setNrOfQuestions(props.nrOfQuestions);
        engine.getTask();
        setIsFlashCard(true);
    }

    return (
        <FlashCardContext.Provider value={{ isCreateFlashcard, setIsCreateFlashcard }}>
            <ScoreContext.Provider value={{ score, reportScore }}>
                <FlashCardScene>
                    {!isCreateFlashcard && !isFlashCard && <FlashCardStartPage
                        launchers={{
                            default: () => launchQuestions({ nrOfQuestions: 50 }),
                            recommended: [
                                { label: "5 frågor", onClick: () => launchQuestions({ nrOfQuestions: 5 }) },
                                { label: "25 frågor", onClick: () => launchQuestions({ nrOfQuestions: 25 }) },
                                { label: "50 frågor", onClick: () => launchQuestions({ nrOfQuestions: 50 }) }
                            ]
                        }}
                    />}

                    {!isCreateFlashcard && isFlashCard && <FlashCard {...flashProps}></FlashCard>}

                    {isCreateFlashcard && <CreateFlashcardSet></CreateFlashcardSet>}
                </FlashCardScene>
            </ScoreContext.Provider>
        </FlashCardContext.Provider>
    );
}

export default FlashCardMode;