import React, { createContext, useContext, useEffect, useState } from "react";
import { useGameEngine } from "./GameEngineContext";
import { useStudent } from "providers/SignInProvider";
import gameService from "services/gameService";

export type Score = {
    score: number,
    scoreInARow: number,
    bestScoreInARow: number,
    loginStreak: number,
};
export type ScoreSetters = {
    reportScore: (challengeScore: number, bonusPoints?: boolean) => void,
};

const initialScore: Score = { score: 0, scoreInARow: 0, bestScoreInARow: 0, loginStreak: 0 };
export const ScoreContext = createContext<{ score: Score } & ScoreSetters>({
    score: initialScore,
    reportScore: () => {}
});

export const ScoreContextProvider = (props: { children: React.ReactNode}) => {
    const [score, setScore] = useState<Score>(initialScore);
    const { engine } = useGameEngine();
    const student = useStudent();

    useEffect(() => {
      if (student && student.id)
        gameService.getStudentScore(student.id.toString()).then(result => {
          setScore({ score: +(result.score ?? 0) + +(result.bonusPoints ?? 0), scoreInARow: +(result.currentStreak ?? 0), bestScoreInARow: +(result.topStreak ?? 0), loginStreak: +(result.loginStreak ?? 0) })
        })
    }, [student])
  
    const reportScore = (challengeScore: number, bonusPoints?: boolean) => {
        const tempScore = Object.assign({}, score)
        tempScore.score += challengeScore

        if (student && student.id) {
          if (challengeScore > 0 && bonusPoints !== true) {
            tempScore.scoreInARow++
            gameService.saveStreakScore(+tempScore.scoreInARow, student.id.toString(), 'current_streak', false).then(result => { })
            if (tempScore.scoreInARow > tempScore.bestScoreInARow) {
              tempScore.bestScoreInARow = tempScore.scoreInARow
              gameService.saveStreakScore(tempScore.scoreInARow, student.id.toString(), 'streak', true).then(result => { })
            }
            if (tempScore.scoreInARow % 5 === 0) {
              engine?.actions.completeEvent({ type: 'celebration', object: 'fire-streak' })
            }
            if (tempScore.score % 100 === 0) {
              engine?.actions.completeEvent({ type: 'celebration', object: 'trophy' })
            }
          }
          else tempScore.scoreInARow = 0
        }
        setScore(tempScore)
      };

    return (
        <ScoreContext.Provider value={{ score, reportScore }}>
             {props.children}
        </ScoreContext.Provider>
    );
}


export const useScore = () => {
  const context = useContext(ScoreContext);
  if (!context) {
      throw new Error("useScore must be used within a GameEngineProvider");
  }
  return context;
};
