import React, { CSSProperties, useState } from 'react';
import skinPng from '../switch_skin-2.png'
import nodePng from '../filter-setting-icon-2.png'
import PowerPng from '../power-off-line-icon.png'
import onboardingPng from '../onboarding.png'
import addFlashCardPng from '../../gameMode/elevert_flashcard_add_icon.png';
import { useAuth } from 'providers/SignInProvider';
import IconButton from 'ux_components/icons';
import Tooltip from 'ux_components/tooltip';
import overlayStyles from '../SceneOverlay.module.scss'
import goldenArrowPng from '../golden_arrow animated.png'
import { useInformationOverlay } from 'contexts/InformationOverlayContext';
import { useFlashCard } from 'contexts/FlashCardContext';

type ControlTileProps = {
    id: string,
    iconId?: string,
    src: string,
    text: string,
    alt: string,
    side: "left" | "right",
    onClick: () => void,
    style?: CSSProperties
}

const ControlTile = (props: ControlTileProps) => {
    const { showOverlay: isInformationOverlay, informationText } = useInformationOverlay();
    const bubbleText = informationText[`${props.id}_img`];

    const style: CSSProperties = {
        marginTop: '10px',
        ...props.style
    }
    return (
        <Tooltip {...{
            id: props.id,
            text: isInformationOverlay ? '' : props.text,
            position: props.side,
            style,
            onClick: props.onClick 
            }}>
            <IconButton {...{
                id: props.iconId ?? `${props.id}`,
                src: props.src,
                alt: props.alt,
                large: true,
                clickable: true
                }}
                class={bubbleText ? overlayStyles.iconButton : ''}
            />
            {bubbleText && <div className={`${overlayStyles.speechBubble} ${overlayStyles[props.side]}`} >{bubbleText}<img src={goldenArrowPng} alt="golden arrow" /></div>}
        </Tooltip>
    )
}

type CommonPredefinedTileProps = {
    side: "left" | "right"
};

export const InfoTile = (props: CommonPredefinedTileProps) => {
    const { setShowOverlay } = useInformationOverlay();

    return (
        <ControlTile
            id='info'
            alt='info button'
            src={onboardingPng}
            text='information'
            side={props.side}
            onClick={() => { setShowOverlay(true) }}
        />
    )
}
export const ModeSwitchTile = (props: CommonPredefinedTileProps & { skinSettingsCallback: () => void }) => {
    return (
        <ControlTile
            id='switch_def' 
            alt='Change skin button'
            src={skinPng}
            text='Byt spel'
            side={props.side}
            onClick={props.skinSettingsCallback}
        />
    )
}
export const SelectNodeTile = (props: CommonPredefinedTileProps & { nodeSettingsCallback: () => void }) => {
    return (
        <ControlTile
            id='select_node'
            alt='Change Subject matter button'
            src={nodePng}
            text='Välj Övningsområde'
            side={props.side}
            onClick={props.nodeSettingsCallback}
        />
    )
}
export const AddFlashcardTile = (props: CommonPredefinedTileProps) => {
    const { setIsCreateFlashcard } = useFlashCard()
    return (
        <ControlTile
            id='flashcard_deck'
            alt='Create new flashcard deck'
            src={addFlashCardPng}
            side={props.side}
            text='Lägg till Flashcard-kortlek'
            onClick={() => setIsCreateFlashcard(true) }
        />
    )
}
export const LogoutTile = (props: CommonPredefinedTileProps) => {
    const [isLoggingOut, setIsLoggingOut] = useState(false)
    const auth = useAuth();
    const { showOverlay: isInformationOverlay, informationText } = useInformationOverlay();
    const bailOut = isInformationOverlay || informationText['logout_img'];

    const handleLogout = () => {
      if (isLoggingOut || bailOut) return;
      setIsLoggingOut(true)
      if (auth?.isLoggedIn)
        auth.logout().then(() => {
          setIsLoggingOut(false)
        })
    }
  
    return (
        <ControlTile
            id='logout'
            alt='Logout button'
            src={PowerPng}
            text='Logga ut'
            side={props.side}
            onClick={handleLogout}
            style={{ cursor: !isLoggingOut ? 'pointer' : 'default', opacity: isLoggingOut ? '0.5' : '1' }}
        />
    )
}
