import { AreaNode } from 'components/settings/AreaNodeSettings/area_nodes';
import { GameGlobalsContext } from 'contexts/GameGlobalsContext';
import { AreaNodeMasteries, GameMode, GameState } from 'engine/game';
import React, { useContext, useEffect, useState } from 'react'
import AdventureTime from './adventureTime';
import OneHundredMode from './oneHundredMode';
import TimedMode from './timedMode';
import FlashCardMode from './flashCardMode';


const SelectedGameMode = (props: {
    mode: GameMode | undefined,
    state: GameState,
    nodes: AreaNode[],
    masteries: AreaNodeMasteries
}) => {
    const {
        mode,
        state,
        nodes,
        masteries
    } = props

    // const [currentMode, setCurrentMode] = useState<GameMode>()
    const [display, setDisplay] = useState<JSX.Element>()

    const gameGlobals = useContext(GameGlobalsContext);

    useEffect(() => {
        const HandleGameMode = () => {
            switch (mode?.type) {
                case 'Timed':
                    return <TimedMode {...{ state, timer: gameGlobals.timer ?? 0, mode }} />
                case 'OneHundred':
                    return <OneHundredMode {...{ state, mode }} />
                case 'FlashCard':
                    return <FlashCardMode></FlashCardMode>
                default:
                    // return <FlashCardMode {...{ state, engine }} ></FlashCardMode>
                    return <AdventureTime {...{ state, nodes, masteries }} />
            }
        }

        // setCurrentMode(mode)
        setDisplay(HandleGameMode())
    }, [mode, state, nodes])

    return <>
        {display}
    </>
}

export default SelectedGameMode;