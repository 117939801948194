import React, { MouseEventHandler, ReactNode, useRef, useState } from 'react';
import styles from './FlashCardFeedback.module.scss';
import goldenArrowPng from './golden-arrow.png';
import greenArrowPng from './golden_green_arrow.png';
import redArrow from './golden_red_arrow.png';

export type FlashcardFeedbackProps = {
    // type: '' | 'correct' | 'incorrect';
    isActive: boolean;
    question: ReactNode;
    correctAnswer: ReactNode;
    taskComment: ReactNode;
    onClick: (isCorrect: boolean) => void;
}

const initialFeedbackState: FlashcardFeedbackProps = {
    isActive: false,
    question: '',
    correctAnswer: '',
    taskComment: '',
    onClick: () => { }
}

export const useFeedbackState = (): [FlashcardFeedbackProps, (newState: FlashcardFeedbackProps) => void] => {
    const [feedbackState, setFeedbackState] = useState(initialFeedbackState);
    const feedbackTimeout = useRef<ReturnType<typeof setTimeout>>();

    const resetFeedbackState = (onClick: (isCorrect: boolean) => void): void => {
        setFeedbackState(initialFeedbackState);
        if (feedbackTimeout.current) {
            feedbackTimeout.current = void clearTimeout(feedbackTimeout.current);
        }
        onClick(false);
    };

    return [
        feedbackState,
        (newFeedbackState) => {
            // setFeedbackTimeoutTrigger(~feedbackTimeoutTrigger);
            setFeedbackState({ ...newFeedbackState, onClick: (isCorrect) => resetFeedbackState(() => newFeedbackState.onClick(isCorrect)) });
        },
    ];
};

const FlashCardFeedback = (props: FlashcardFeedbackProps) => {
    return (
        <div id={`feedback`} className={`${styles.feedback} ${props.isActive ? styles.active : ''}`}>
            <div style={{ height: '100%' }}>
                <div className={`${styles.feedbackBody} ${props.isActive ? styles.active : ''}`}>
                    <div style={{ gridColumn: "top" }}>
                        <div>Feedback</div>
                        {props.question}
                        <br />
                        <br />
                    </div>
                    <div style={{ gridColumn: "leftTop" }}>
                        {props.correctAnswer}
                    </div>
                    <div style={{ gridColumn: "rightTop" }}>
                        {props.taskComment}
                    </div>
                    <div style={{ gridColumn: "leftBottom" }}>
                        <img src={greenArrowPng} alt="left Arrow" className={styles.leftArrow} onClick={() => props.onClick(true)} />
                        <div>Rätt</div>
                    </div>
                    <div style={{ gridColumn: "rightBottom" }}>
                        <img src={redArrow} alt="right Arrow" className={styles.rightArrow} onClick={() => props.onClick(false)} />
                        <div>Fel</div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default FlashCardFeedback;