import React, { useEffect, useState } from 'react';
import styles from './CreateFlashcard.module.scss';

function FlashcardBodyElement(props: {
    isEditable: true,
    text: string,
    placeholder: string,
    setText: (value: string) => void
    onKeyDown: (e: React.KeyboardEvent<HTMLTextAreaElement>) => void,
} | {
    isEditable?: false,
    text: string,
}) {
    return <div style={{ padding: "20px" }}>
        <div style={{ width: '200px', height: "50px", margin: '', textAlign: "center" }}>
            {props.isEditable ? <textarea className={styles.textArea} placeholder={props.placeholder} value={props.text} onChange={e => props.setText(e.target.value)} onKeyDown={props.onKeyDown} />
                : <span className={styles.cardText}>{props.text}</span>}
        </div>
    </div>
}

function FlashCardElement(props: {
    className?: string,
    isEdit: boolean,
    setEdit?: (value: boolean) => void,
    flipCard?: () => void,
    rotateCard?: boolean,
    children: React.ReactNode,
}) {
    const [rotate, setRotate] = useState<boolean>(false);
    const [rotateBack, setRotateBack] = useState<boolean>(false);

    const flipCard = () => {
        console.log("flipCard", rotate, rotateBack);
        if (rotate || rotateBack) return;
        setRotate(true);
        setTimeout(() => {
            props.flipCard?.();
            setRotateBack(true);
            setTimeout(() => {
                setRotate(false);
                setRotateBack(false);
            }, 600);
        }, 500);
    }

    useEffect(() => {
        if (props.rotateCard) {
            flipCard();
        }
    }, [props.rotateCard])

    return <div>
        <div className={`${styles.editCard} ${rotate ? styles.rotateY : ''} ${rotateBack ? styles.rotateBackY : ''} ${props.className ?? ''}`}>
            {props.children}
        </div>
    </div>
}

export { FlashCardElement, FlashcardBodyElement };