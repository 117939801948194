import React, { useContext, useEffect, useState } from "react";
import { useStudent } from "providers/SignInProvider";
import gameService from "services/gameService";
import { GameGlobalsContext } from "contexts/GameGlobalsContext";
import ScoreTile from "./ScoreTiles";
import timerPng from '../timer.png'
import { useGameEngine } from "contexts/GameEngineContext";
import { useInformationOverlay } from "contexts/InformationOverlayContext";

export const displayTime = (timeInMS: number, startTimeInMS: number) => {
    const time = Math.round((timeInMS - startTimeInMS) / 1000)
  
    return time
}
  
const StopTimerTile = (props: { side: "right" | "left", timeLimit?: number }) => {

    const student = useStudent();
    const gameGlobals = useContext(GameGlobalsContext);
    const [score, setScore] = useState<number>();
    const { engine } = useGameEngine();
    const { informationText } = useInformationOverlay();

    const informationSpeechBubbleText = informationText['timed_mode'];

    const onClick = () => engine?.actions.switchGameMode({
      type: 'Timed',
      isTimed: true,
      startTime: gameGlobals.timer ?? 0,
      timeLimit: props.timeLimit ?? 60
    });

    useEffect(() => {      
      let running = true
      if (score === undefined && student && student.id) {
        setScore(0)
        if (running) {
          gameService.getTimedModeScore(student.id.toString()).then(result => {
            const mode = result['mode'] ?? { 'score': 0 }
            const score = mode['score'] ?? 0
            setScore(score)
          }).catch(e => {
            console.error('error', e)
            setScore(0)
          })
        }
      }
      return () => { running = false }
    }, [score, student])
  
    return <>
      <ScoreTile {...{
        id: 'timed_mode',
        src: timerPng,
        alt: 'timer',
        side: props.side,
        score: !!score ? `${score}%` : '0',
        tooltip: 'Snabbspel',
        clickable: true,
        informationSpeechBubbleText,
        onClick
      }} />
  
      {gameGlobals.gameMode?.isTimed && <><br /><div>{displayTime(gameGlobals.timer ?? 0, gameGlobals.gameMode.startTime)}</div></>}
    </>
  }
  
  export default StopTimerTile
  