import React, { useState } from 'react';
import { useAuth } from 'providers/SignInProvider';
import styles from './sideMenus.module.scss'
import { AddFlashcardTile, InfoTile, LogoutTile, ModeSwitchTile, SelectNodeTile } from './menuTiles/ControlTiles';
import { SideMenuBanner } from './menuTiles/SideMenuBanner';
import { useInformationOverlay } from 'contexts/InformationOverlayContext';

export type SupportedControlTile = "info" | "logout" | "modeSwitch" | "selectNode" | "addFlashCard";

function Control(props: {
  className: string,
  tiles: SupportedControlTile[],
  skinSettingsCallback: () => void,
  nodeSettingsCallback: () => void,
}) {
  const [show, setShow] = useState(true)
  const auth = useAuth();
  const { showOverlay: isInformationOverlay } = useInformationOverlay();

  return (
    <div className={props.className} style={{ textAlign: 'center' }} >

      <SideMenuBanner
          idPreamble='controls'
          bannerText='Inställningar'
          side="left"
          onClick={() => setShow(s => !s)}
      />
      {<div className={show ? (styles.scores + ' ' + styles.show) : styles.scores} style={isInformationOverlay ? { zIndex: 0 } : {}}>

        {props.tiles.map(tile => {
          switch (tile) {
            case "info":
              return <InfoTile side='left' />;
            case "modeSwitch":
              return <ModeSwitchTile
                side='left'
                skinSettingsCallback={props.skinSettingsCallback}
              />;
            case "selectNode":
              return <SelectNodeTile
                side='left'
                nodeSettingsCallback={props.nodeSettingsCallback}
              />;
            case "addFlashCard": 
              return <AddFlashcardTile side='left' />
            case "logout":
              return auth?.isLoggedIn &&
                <LogoutTile
                  side='left'
                />;
          }
        })}

      </div>}
    </div>
  );
}

export default Control;
