import React from 'react';
import Control from 'components/sideMenus/Control';
import Stats from 'components/sideMenus/Stats';
import gameFrameStyles from './../../components/gameFrame/GameFrame.module.scss'
import styles from './InformationOverlay.module.scss'
import { ScoreContext } from 'contexts/ScoreContext';
import { useInformationOverlay } from 'contexts/InformationOverlayContext';

  
const InformationOverlay = () => {
    const { showOverlay, handleIndex } = useInformationOverlay();
    const leftHanded = false;

    const demoScore = { score: 25, scoreInARow: 10, bestScoreInARow: 15, loginStreak: 3 };

    return (<>
        {showOverlay && <div style={{ backgroundColor: '#000a', zIndex: 10, position: 'absolute', top: 0, right: 0, bottom: 0, left: 0 }} onClick={handleIndex}>
            <div className={styles.container}>
                <ScoreContext.Provider value={{ score: demoScore, reportScore: () => {}}}>
                    <Stats className={leftHanded ? gameFrameStyles.leftOverlay : gameFrameStyles.rightOverlay} tiles={["totalScore", "streakScore", "stopTimer", "oneHundred"]} />
                    <Control className={leftHanded ? gameFrameStyles.rightOverlay : gameFrameStyles.leftOverlay} skinSettingsCallback={() => { }} nodeSettingsCallback={() => { }} tiles={["info", "modeSwitch", "selectNode", "logout"]} />
                </ScoreContext.Provider>
            </div>
        </div>}
    </>);
};

export default InformationOverlay;